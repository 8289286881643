@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,600;1,400&display=swap");
body {
  font-family: "Montserrat", sans-serif;
}
[x-cloak] {
  display: none !important;
}
#main_nav {
  position: fixed;
  top: 0;
  transition: transform 0.4s;
}
#main_nav.nav--hidden {
  transform: translateY(-160px);
}
